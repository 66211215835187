import React, { useState } from "react";
import "./StepBody.scoped.scss";
import useSelector from "store/useSelector";
import { SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import { getStepComments, getStepResponse, getSWInstanceTitle, shouldStepBeDisabled } from "utilities/swUtilities";
import CompleteControls from "components/execution/sw/controls/CompleteControls";
import { useDispatch } from "react-redux";
import { setStepCompletion, logJobAction, setStepIsNotApplicable, setConditionalResponse, setStepCommentModalData, saveExecutionData } from "store/execution/executionActions";
import NotApplicableControls from "components/execution/sw/controls/NotApplicableControls";
import ToggleButtonInput from "components/execution/sw/controls/ToggleButtonInput";
import { ILinkData, IStep, StepComponentTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import Notice from "./components/Notice";
import Image from "./components/Image";
import DateInput from "./components/DateInput";
import NumberInput from "./components/NumberInput";
import TextInput from "./components/TextInput";
import PhotoInput from "./components/PhotoInput";
import SelectInput from "./components/SelectInput";
import MultiSelectInput from "./components/MultiSelectInput";
import SignatureInput from "./components/SignatureInput";
import YesNoInput from "./components/YesNoInput";
import { ExecutionMode, IStepIdentifier } from "store/execution/executionTypes";
import { doesStepHaveIncompleteInput } from "utilities/swUtilities";
import { showErrorToast } from "store/toast/toastActions";
import commentIcon from "media/icons/dls/comments.svg";
import StepCommentModal from "./comments/StepCommentModal";
import BlockSpinner from "components/common/BlockSpinner";
import MicrosoftStreamVideo from "./components/MicrosoftStreamVideo";
import { useTranslation } from "react-i18next";
import TableComponent from "./components/TableComponent";
import PPEComponent from "./components/PPEComponent";
import Video from "./components/Video";
import RichTextParagraph from "./components/RichTextParagraph";
import VideoInput from "./components/VideoInput";
import Audio from "./components/Audio";
import AudioInput from "./components/AudioInput";
import TIMEImage from "./components/TIMEImage";
import PassFailInput from "./components/PassFailInput";
import Banner, { BannerType } from "components/common/Banner";

interface IStepBodyProps {
  jobSWId: number,
  step: IStep,
}

const StepBody: React.FC<IStepBodyProps> = ({ jobSWId, step }) => {
  const {
    execution: {
      job,
      stepCommentModalData,
      stepResponses,
      stepComments,
      executor,
      realtimeStepsLoading,
      paperExecutions,
      mode,
    },
    auth,
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('step');
  const [conditionalDataResponse, setConditionalDataResponse] = useState(step.conditionalData ? step.conditionalData.expectedValue : "");

  const stepInfo: IStepIdentifier = {
    stepId: step.id,
    jobSWId,
  };

  if (!job) {
    return <span><i>No job is currently loaded.</i></span>;
  }

  const hasComments = !!getStepComments(stepInfo, stepComments).length;

  const sw = job.sws
    .find(sw => sw.jobSWId === jobSWId);

  if (!sw) {
    return null;
  }

  const jobId = job.id;

  const stepResponse = getStepResponse(stepInfo, stepResponses);

  let isJobCompleted = job.status === SWPJobStatus.Completed;
  // Disable the step if the job is completed or if the user isn't on the team.

  const isRealtimeLoading = realtimeStepsLoading.find(x => x.jobSWId === sw.jobSWId
    && x.stepId === step.id);

  let isStepDisabled = isJobCompleted
    || !job.team.find(x => x.email.toLowerCase() === auth.currentUser.email.toLowerCase())
    || !!isRealtimeLoading
    || !!paperExecutions.find(x => x.jobSWId === stepInfo.jobSWId);
  let isStepCompleted = stepResponse?.isComplete || false;

  if (step.parentId) {
    const currSW = job.sws.find(jsw => jsw.jobSWId === jobSWId);

    if (!currSW) {
      return null;
    }

    if (shouldStepBeDisabled(currSW, step.id, stepResponses)) {
      isStepDisabled = true;
    }
  }

  const onNotApplicableClicked = () => {
    if (stepResponse?.isNA) {
      dispatch(setStepIsNotApplicable({
        isNA: false,
        stepInfo,
        userEmail: executor?.email || auth.currentUser.email,
      }));
      dispatch(setStepCompletion({
        isComplete: false,
        stepInfo,
        userEmail: executor?.email || auth.currentUser.email,
      }));
      dispatch(logJobAction({
        action: `Unmarked step ${step.number} N/A in ${getSWInstanceTitle(jobSWId, job.sws)}`,
        timestamp: new Date(),
        userName: executor?.name || auth.currentUser.name,
        userEmail: executor?.email || auth.currentUser.email,
      }));
    } else {
      setChildStepsAsNotApplicable(step);

      dispatch(setStepIsNotApplicable({
        isNA: true,
        stepInfo,
        userEmail: executor?.email || auth.currentUser.email,
      }));
      dispatch(setStepCompletion({
        isComplete: true,
        stepInfo,
        userEmail: executor?.email || auth.currentUser.email,
      }));
      dispatch(logJobAction({
        action: `Marked step ${step.number} N/A and complete in ${getSWInstanceTitle(jobSWId, job.sws)}`,
        timestamp: new Date(),
        userName: executor?.name || auth.currentUser.name,
        userEmail: executor?.email || auth.currentUser.email,
      }));
    }

    if (mode === ExecutionMode.Offline) {
      dispatch(saveExecutionData());
    }
  }

  //Set all child steps as Not Applicable 
  const setChildStepsAsNotApplicable = (step: IStep) => {
    if (!step.isTask) {
      for (let i = 0; i < step.children.length; i++) {
        let subStep = step.children[i];
        let childStepInfo = {
          stepId: subStep.id,
          jobSWId: stepInfo.jobSWId
        };
        setChildStepsAsNotApplicable(subStep);

        dispatch(setStepIsNotApplicable({
          isNA: true,
          stepInfo: childStepInfo,
          userEmail: executor?.email || auth.currentUser.email
        }));
        dispatch(setStepCompletion({
          isComplete: true,
          stepInfo: childStepInfo,
          userEmail: executor?.email || auth.currentUser.email,
        }));
        dispatch(logJobAction({
          action: `Marked step ${subStep.number} N/A and complete in ${getSWInstanceTitle(jobSWId, job.sws)}`,
          timestamp: new Date(),
          userName: executor?.name || auth.currentUser.name,
          userEmail: executor?.email || auth.currentUser.email,
        }));
      }
    }
  }

  const onCompleteClicked = () => {
    if (isStepCompleted) {
      dispatch(setStepCompletion({
        isComplete: false,
        stepInfo,
        userEmail: executor?.email || auth.currentUser.email,
      }));
      dispatch(setStepIsNotApplicable({
        isNA: false,
        stepInfo,
        userEmail: executor?.email || auth.currentUser.email,
      }));
      dispatch(logJobAction({
        action: `Reopened step ${step.number.replace(/\.+$/, "")} in ${getSWInstanceTitle(jobSWId, job.sws)}`,
        timestamp: new Date(),
        userName: executor?.name || auth.currentUser.name,
        userEmail: executor?.email || auth.currentUser.email,
      }));
    } else {
      // Check to see if any components are incomplete.
      if (doesStepHaveIncompleteInput(step, stepResponse, sw.type)) {
        dispatch(showErrorToast(t("The step can't be completed until all inputs are completed.")));
        return;
      }

      dispatch(setStepCompletion({
        isComplete: true,
        stepInfo,
        userEmail: executor?.email || auth.currentUser.email,
      }));

      if (step.isConditional && !getConditionalResponse()) {
        for (const childStep of step.children) {
          dispatch(setStepIsNotApplicable({
            isNA: true,
            stepInfo: {
              jobSWId,
              stepId: childStep.id
            },
            userEmail: executor?.email || auth.currentUser.email
          }));

          dispatch(setStepCompletion({
            isComplete: true,
            stepInfo: {
              jobSWId,
              stepId: childStep.id
            },
            userEmail: executor?.email || auth.currentUser.email,
          }));
        }
      }

      dispatch(logJobAction({
        action: `Completed step ${step.number.replace(/\.+$/, "")} in ${getSWInstanceTitle(jobSWId, job.sws)}`,
        timestamp: new Date(),
        userName: executor?.name || auth.currentUser.name,
        userEmail: executor?.email || auth.currentUser.email,
      }));
    }
    if (mode === ExecutionMode.Offline) {
      dispatch(saveExecutionData());
    }
  }

  const getConditionalResponse = () => {
    return stepResponse
      ?.conditionalResponse;
  }

  const onConditionalResponseChange = (response: boolean) => {
    setConditionalDataResponse(response ? "Yes" : "No");
    dispatch(setConditionalResponse({
      response,
      stepInfo,
      userEmail: executor?.email || auth.currentUser.email,
    }));
  }

  return (
    <div className="step-container">
      <div className="step">
        <div className="body">
          {step.components.map(comp => {
            let renderComp: JSX.Element | undefined;

            if (comp.type === StepComponentTypes.Notice) {
              renderComp = (
                <Notice
                  notice={comp}
                  swId={sw.id}
                  swVersion={sw.version}
                />
              );
            } else if (comp.type === StepComponentTypes.Image) {
              renderComp = (
                <Image
                  swId={sw.id}
                  swVersion={sw.version}
                  image={comp}
                />
              );
            } else if (comp.type === StepComponentTypes.TimeImage) {
              renderComp = (
                <TIMEImage
                  swId={sw.id}
                  swVersion={sw.version}
                  image={comp}
                />
              );
            } else if (comp.type === StepComponentTypes.DateInput
              || comp.type === StepComponentTypes.DateTimeInput) {
              renderComp = (
                <DateInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                />
              );
            } else if (comp.type === StepComponentTypes.NumberInput) {
              renderComp = (
                <NumberInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                />
              );
            } else if (comp.type === StepComponentTypes.TextInput) {
              renderComp = (
                <TextInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                />
              );
            } else if (comp.type === StepComponentTypes.PhotoInput) {
              renderComp = (
                <PhotoInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                  isJobCompleted={isJobCompleted}
                />
              );
            } else if (comp.type === StepComponentTypes.VideoInput) {
              renderComp = (
                <VideoInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                  isJobCompleted={isJobCompleted}
                />
              );
            } else if (comp.type === StepComponentTypes.AudioInput) {
              renderComp = (
                <AudioInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                  isJobCompleted={isJobCompleted}
                />
              );
            } else if (comp.type === StepComponentTypes.SelectInput) {
              renderComp = (
                <SelectInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                />
              );
            } else if (comp.type === StepComponentTypes.MicrosoftStreamVideo) {
              renderComp = (
                <MicrosoftStreamVideo
                  component={comp}
                />
              );
            } else if (comp.type === StepComponentTypes.MultiSelectInput) {
              renderComp = (
                <MultiSelectInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                />
              );
            } else if (comp.type === StepComponentTypes.SignatureInput) {
              renderComp = (
                <SignatureInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                  jobId={jobId}
                />
              );
            } else if (comp.type === StepComponentTypes.YesNoInput) {
              renderComp = (
                <YesNoInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                />
              );
            } else if (comp.type === StepComponentTypes.PassFailInput) {
              renderComp = (
                <PassFailInput
                  component={comp}
                  isDisabled={isStepDisabled || isStepCompleted}
                  stepInfo={stepInfo}
                />
              );
            } else if (comp.type === StepComponentTypes.Link) {
              renderComp = (
                <a
                  href={(comp.data as ILinkData).url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {comp.label}
                </a>
              )
            } else if (comp.type === StepComponentTypes.Table || comp.type === StepComponentTypes.EnhancedTable) {
              renderComp = (
                <TableComponent
                  component={comp}
                  swId={sw.id}
                  swVersion={sw.version}
                  stepInfo={stepInfo}
                />
              )
            } else if (comp.type === StepComponentTypes.PPE) {
              renderComp = (
                <PPEComponent
                  component={comp}
                />
              )
            } else if (comp.type === StepComponentTypes.Video) {
              renderComp = (
                <Video
                  swId={sw.id}
                  swVersion={sw.version}
                  video={comp}
                />
              )
            } else if (comp.type === StepComponentTypes.Audio) {
              renderComp = (
                <Audio
                  swId={sw.id}
                  swVersion={sw.version}
                  audio={comp}
                />
              )
            }
            else if (comp.type === StepComponentTypes.RichTextParagraph) {
              renderComp = (
                <RichTextParagraph
                  component={comp}
                />
              )
            } else if (comp.type === StepComponentTypes.FormulaNumerical) {
              renderComp = (
                <div className="formula-numerical">
                  <label>{comp.label}</label>
                  <p>
                    This is a Formula (Numerical) Component. Ignore this
                    and continue.
                  </p>
                </div>
              );
            }

            if (!renderComp) {
              return null;
            }

            return (
              <div
                className="step-component"
                key={comp.id}
              >
                {renderComp}
              </div>
            )
          })}

          {step.isConditional && step.conditionalData &&
            <div className="step-component">
              {step.conditionalData.componentType === StepComponentTypes.YesNoInput &&
                <>
                  {step.conditionalData.expectedValue &&
                    conditionalDataResponse
                    && (step.conditionalData.expectedValue !== conditionalDataResponse) &&
                    <Banner
                      type={BannerType.Info}
                    >
                      <div className="conformInfo">
                        <label>Expected Value:&nbsp;</label>
                        <span>{step.conditionalData.expectedValue}</span>
                      </div>
                    </Banner>
                  }
                  <ToggleButtonInput
                    options={["Yes", "No"]}
                    onAnswerChosen={value => onConditionalResponseChange(value === "Yes")}
                    disabled={isStepDisabled || isStepCompleted}
                    currentValue={(getConditionalResponse() === undefined
                      || getConditionalResponse() === null)
                      ? undefined
                      : (
                        getConditionalResponse() === true
                          ? "Yes"
                          : "No"
                      )}
                  />
                </>
              }
            </div>
          }
        </div>

        {!isJobCompleted && (!step.isTask || (step.isTask && !step.children.length)) &&
          <div className="controls">
            <div className="button-holder">
              <NotApplicableControls
                isStepNA={stepResponse?.isNA || false}
                onClick={onNotApplicableClicked}
                disabled={isStepDisabled || isStepCompleted}
              />
            </div>
            <div className="button-holder">
              <div className="complete-row">
                <CompleteControls
                  disabled={isStepDisabled}
                  hasIncompleteInputs={doesStepHaveIncompleteInput(step, stepResponse, sw.type)}
                  isStepCompleted={isStepCompleted}
                  onClick={onCompleteClicked}
                  autoComplete={sw.type === SWTypes.CL || sw.type === SWTypes.LCL}
                />
                <button
                  className={`${hasComments
                    ? "primary"
                    : "secondary"}-button comment-button`}
                  disabled={isStepDisabled}
                  onClick={() => dispatch(setStepCommentModalData({
                    isVisible: true,
                    stepIdentifier: stepInfo,
                  }))}
                >
                  <img
                    src={commentIcon}
                    alt="Comments"
                  />
                </button>
              </div>
            </div>
          </div>
        }
      </div>

      {stepCommentModalData.isVisible &&
        <StepCommentModal
          stepInfo={stepInfo}
        />
      }

      {isRealtimeLoading &&
        <div
          className="realtime-container"
        >
          <BlockSpinner />
        </div>
      }
    </div>
  );
}

export default StepBody;